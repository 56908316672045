import { AppSettings } from "./interfaces/App-Settings";

const appSettings: AppSettings = {
    //apiPath: "https://localhost:7102",

    //Prod
    apiPath: "https://api.dev-engadin.chili.ch",
    forumUrl: ""
}

export default appSettings;