import * as React from "react";
import { Switch, Route } from "react-router-dom";
import * as loadable from 'react-loadable';
import Loading from "./components/loading/Loading";
import { useThunkDispatch } from "./util/thunk-dispatch";
import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import { AppState } from "./interfaces/App-State";

const AsyncViewContainer = loadable({ loader: () => import(/* webpackChunkName: "Logout" */ "./containers/View/OfferView"), loading: Loading });
const AsyncViewPageContainer = loadable({ loader: () => import(/* webpackChunkName: "Logout" */ "./containers/View/OfferPage"), loading: Loading });
const AsyncViewCreateContainer = loadable({ loader: () => import(/* webpackChunkName: "Logout" */ "./containers/View/OfferCreate"), loading: Loading });

const Routes: React.FC = () => {

    return (
        <Switch>
            <Route exact path="/offerCreate/:lan/:dealId" render={() => <AsyncViewCreateContainer />} />
            <Route exact path="/offer/:lang/:season/:dealId/:key" render={() => <AsyncViewPageContainer />} />
            <Route exact path="/:dealId" render={() => <AsyncViewContainer />} />
        </Switch>
    );
}

export default Routes;