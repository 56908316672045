import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
    Button, Grid, IconButton, Box, Divider,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Navigation from "../navigation/Navigation";
import { useSelector } from "react-redux";
import { Link as RouterLink, Link } from "react-router-dom";
import { AppState, initialState, } from "../../interfaces/app-state";

export const drawerWidth = 250;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        drawer: {
            [theme.breakpoints.up("lg")]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.up("lg")]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up("lg")]: {
                display: "none",
            },
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: "#d6d6d6",
            color: "#fff",
            overflow: "hidden"
        },
        title: {
            flexGrow: 1,
            lineHeight: 1,
            color: "black",
        },
        link: {
            color: "black",
            textDecoration: "none",
        },
    })
);


const Header: React.FC = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
    }, [])

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent
    ) => {
        if (
            event &&
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" ||
                (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }
        setIsOpen(open);
    };

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={4}>
                    <RouterLink to="/">
                        <img src="/assets/img/baltensperger-logo.png" width="220px" />
                    </RouterLink>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h5" className={classes.title}>
                        <Box sx={{ m: 3 }} />
                        <Box display="flex" justifyContent="center" alignItems="center" >
                            Check App
                        </Box>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Box display="flex" justifyContent="right" alignItems="right" style={{float:"right"}}>
                    </Box>
                </Grid>
            </Grid>
            <Divider />
            <Box sx={{ m: 2 }} />
        </div>
    );
};

export default Header;
