import { createTheme, createStyles, makeStyles, Theme, ThemeProvider } from "@material-ui/core";
import * as React from "react";
import { bindActionCreators } from "redux";
import Header, { drawerWidth } from "../components/header/Header";
import Routes from "../routes";
import { useThunkDispatch } from "../util/thunk-dispatch";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: "block",
            flexGrow: 1,
            [theme.breakpoints.up("lg")]: {
                width: `calc(100vw - ${drawerWidth + 115}px)`,
                marginLeft: drawerWidth,
                padding: "45px 0 45px 45px"
            },
            marginTop: "0px",
            padding: "45px 45px 45px 45px"
        },
        toolbar: theme.mixins.toolbar,
    })
);

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            light: '#ababab',
            dark: '#d6d6d6',
            main: '#ffffff',
            contrastText: '#000',
        },
        secondary: {
            main: '#387c6c',
            dark: '#fff',
        },
    }
});

const App: React.FC = () => {
    const classes = useStyles();
    const asyncDispatch = useThunkDispatch();



    return (
        <>
            <ThemeProvider theme={theme}>
                    <Routes />
            </ThemeProvider>
        </>
    );
}

export default App;